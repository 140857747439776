import React from 'react';

import Helmet from 'react-helmet';
import styled from 'styled-components';
import { Padding } from 'styled-components-spacing';

import Card from '@nib/card';
import {
  FamilyGraphicIcon,
  AssessmentGraphicIcon,
  ClockGraphicIcon,
  PriceGraphicIcon,
  SingleGraphicIcon,
  CoupleGraphicIcon,
  CoffinGraphicIcon,
  SavingsGraphicIcon,
  IncomeInsuranceGraphicIcon,
} from '@nib/icons';
import { Box, Container, Inline, Section, Stack, Tiles } from '@nib/layout';

import { PrimaryButton, SecondaryButton } from '@nib-components/button';
import colors from '@nib-components/colors';
import Copy, { Bold } from '@nib-components/copy';
import Link from '@nib-components/link';
import Heading from '@nib-components/heading';

import Layout from '../../components/Layout';
import metrics from '../../metrics';
import { urlConstants } from '../../constructs/constants';
import { StyledCard } from '../../components/styledComponents';
import CallBackModal from '../../components/CallBackModal';
import HeroPanel from '@nib-components/hero-panel';
import { linkLoud } from '@nib-components/theme';
import useCampaign from '../../hooks/useCampaign';
import { graphql } from 'gatsby';

const cnMigrantJoin = `${urlConstants.cnJoin}&cs=Migrant`;

const ValueSection = (): JSX.Element => (
  <Section>
    <Features />
  </Section>
);

interface ImageShape {
  file: {
    url: string;
  };
}

interface RawAndHtmlMarkdownShape {
  childMarkdownRemark: {
    html: string;
    rawMarkdownBody: string;
  };
}

interface HeroImagesShape {
  desktop: ImageShape;
  desktop2x4000x1200: ImageShape;
  mobile2x800x300: ImageShape;
  mobile400x150: ImageShape;
  tablet2x1600x580: ImageShape;
  tablet800x290: ImageShape;
}

interface NodeShape {
  title: string;
  startDate: string;
  heroTitle: string;
  heroCopy: RawAndHtmlMarkdownShape;
  legal: RawAndHtmlMarkdownShape;
  campaignCode: string;
  heroImages: HeroImagesShape;
}

type HealthInsuranceCNPageResult = {
  edges: {
    node: NodeShape;
  }[];
};

type GraphQLResult = {
  allContentfulHealthInsuranceCnPage: HealthInsuranceCNPageResult;
};

interface HealthInsuranceCNProps {
  data: GraphQLResult;
}
const StyledCopy = styled.div`
  a {
    color: ${linkLoud};
  }
}`;

interface ContentSectionProps {
  html: string;
  className?: string;
}

const ContentSection = (props: ContentSectionProps): JSX.Element => {
  const { html } = props;
  return (
    <Container className={props.className}>
      <Copy measure={false}>
        <StyledCopy dangerouslySetInnerHTML={{ __html: html }} />
      </Copy>
    </Container>
  );
};

type HeaderSectionProps = {
  title: string;
  copy: RawAndHtmlMarkdownShape;
  images: HeroImagesShape;
  legalCopy: RawAndHtmlMarkdownShape;
};

const HeaderSection = ({ title, copy, images, legalCopy }: HeaderSectionProps): JSX.Element => (
  <HeroPanel
    variation="condensed"
    images={{
      desktop: images?.desktop?.file?.url,
      desktop2x: images?.desktop2x4000x1200?.file?.url,
      mobile: images?.mobile400x150?.file?.url,
      mobile2x: images?.mobile2x800x300?.file?.url,
      tablet: images?.tablet800x290?.file?.url,
      tablet2x: images?.tablet2x1600x580?.file?.url,
    }}
  >
    {title && (
      <Heading component="p" size={{ xs: 2, md: 1 }} fontType="serif" color="trueGreen">
        {title}
      </Heading>
    )}
    <ContentSection html={copy?.childMarkdownRemark?.html} />
    <Inline space={4} collapseBelow="md" align="left" verticalAlign="bottom">
      // write code to make the text of the buttons dynamic
      <PrimaryButton href="/life-cover-options?#estimator-tool">估算您的保额</PrimaryButton>
      <SecondaryButton href="/life-cover-options?#book-a-call">申请回电</SecondaryButton>
    </Inline>
    <Copy>
      <StyledCopy dangerouslySetInnerHTML={{ __html: legalCopy?.childMarkdownRemark?.html }} />
    </Copy>
  </HeroPanel>
);
const BoxWrapper = styled(Box)`
  box-sizing: border-box;
  text-align: center;
`;

const LifeInsuranceBenefits = (): JSX.Element => {
  return (
    <div>
      <Box display="flex" justifyContent="center" marginBottom={4}>
        <Heading
          color="trueGreen"
          size={{ sm: 4, md: 3, lg: 2 }}
          component="h2"
          style={{ textAlign: 'center' }}
        >
          以下是为什么要考虑
          <br />
          人寿保险和生活保险的四大原因：
        </Heading>
      </Box>
      <Box>
        <Tiles space={{ xs: 4, md: 6 }} columns={{ xs: 1, xl: 2 }}>
          <BenefitTile
            icon={IncomeInsuranceGraphicIcon}
            title="1. 保障您的收入"
            tileCardContent="如果您因疾病无法工作，收入保障疾病保险可以帮助您支付日常开支，如房贷、房租或杂货生活用品 费用，让您可以专注于康复。"
          />
          <BenefitTile
            icon={SavingsGraphicIcon}
            title="2. 一次性付款，帮您应对开销"
            tileCardContent="如果您被确诊患有特定 的重大疾病（如严重心脏病或严重癌症），重大疾病创伤保险将提供一次性赔付。这笔资金可用于您需要的任何支出—例如 额外的医疗费用、收入替代，或者度假修养。"
          />
          <BenefitTile
            icon={FamilyGraphicIcon}
            title="3. 为家人留出哀悼的空间"
            tileCardContent="如果您不幸去世，可能会给您的亲人带来重大的 经济压力。人寿保险可以帮助减轻这种压力，让您的家人有时间哀悼和适应变化。"
          />
          <BenefitTile
            icon={CoffinGraphicIcon}
            title="4. 覆盖您的葬礼费用"
            tileCardContent="任何nib人寿保险或生活保险均提供免费殡葬费用保障。如果您去世，或被诊断出末期疾病且预计在未来12个月内去世，此保险将一次性赔付$15,000。这能帮助您的家人应对殡葬开销，减轻经济负担或担忧。"
          />
        </Tiles>
      </Box>
      <Box textAlign="center" marginTop={6}>
        <PrimaryButton color="dark" href={urlConstants.cnJoin}>
          获取报价
        </PrimaryButton>
      </Box>
    </div>
  );
};
const BenefitSection = (): JSX.Element => (
  <Section>
    <Stack space={4}>
      <LifeInsuranceBenefits />
    </Stack>
  </Section>
);

interface FeatureTileProps {
  icon: any;
  title: string | JSX.Element;
}

const FeatureTile = ({ icon: Icon, title }: FeatureTileProps): JSX.Element => (
  <BoxWrapper
    padding={5}
    paddingVertical={{ lg: 6 }}
    height="100%"
    style={{ boxShadow: `${colors.sneezy} 0px 4px 8px 0px` }}
  >
    <Icon size="xl" fill="trueGreen" />
    <Padding top={4}>
      <Box textAlign="center">
        <Heading size={4} component="h3">
          {title}
        </Heading>
      </Box>
    </Padding>
  </BoxWrapper>
);

interface BenefitTileProps {
  icon: any;
  title: string | JSX.Element;
  tileCardContent: string;
}

const BenefitTile = ({ icon: Icon, title, tileCardContent }: BenefitTileProps): JSX.Element => (
  <BoxWrapper padding={5} paddingVertical={{ lg: 6 }} height="100%" style={{ boxShadow: 'none' }}>
    <Icon size="xl" />
    <Padding top={4}>
      <Box textAlign="center">
        <Heading size={4} component="h3" color="trueGreen">
          {title}
        </Heading>
        <p>{tileCardContent}</p>
      </Box>
    </Padding>
  </BoxWrapper>
);

const Features = (): JSX.Element => (
  <div>
    <Box display="flex" justifyContent="center" marginBottom={4}>
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
        更多保障选择：nib 也为您提供医疗保险。
      </Heading>
    </Box>
    <Padding bottom={5}>
      <Tiles columns={{ xs: 1, md: 2, lg: 4 }} space={{ xs: 4, md: 6 }}>
        <FeatureTile
          icon={FamilyGraphicIcon}
          title="我们为一百多万新西兰及澳大利亚人提供保险保障"
        />
        <FeatureTile icon={AssessmentGraphicIcon} title="使您得以快速获取医疗服务。" />
        <FeatureTile
          icon={ClockGraphicIcon}
          title={
            <div>
              每个人、每对配偶，
              <br />
              每个家庭都能找到适合自身需求的保险方案。
            </div>
          }
        />
        <FeatureTile icon={PriceGraphicIcon} title="简便、智能，高性价比" />
      </Tiles>
    </Padding>
  </div>
);

const QuoteSection = (): JSX.Element => (
  <Section background="warmWhite60">
    <Stack space={4}>
      <Box display="flex" justifyContent="center" marginBottom={4}>
        <Heading
          color="trueGreen"
          size={{ sm: 4, md: 3, lg: 2 }}
          component="h2"
          style={{ textAlign: 'center' }}
        >
          适合您生活方式的保险方案才是最恰当的选择。
        </Heading>
      </Box>
      <Copy measure={false}>
        nib的保险方案既包括牙医、验光、骨科及针灸等日常医疗保障内容，也有应对例如癌症治疗、手术费用、术后护理等意外状况的保障内容，必有一种方案适合您。
      </Copy>
      <Tiles columns={{ xs: 1, md: 3 }} space={{ xs: 4, md: 6 }} flex>
        <StyledCard icon={SingleGraphicIcon} align="center">
          <Card.Content title="单身被保险人">
            <Copy>
              <Bold>单身医疗保险</Bold>
            </Copy>
            <Copy>从日常医疗需求保障到全面医院保险，您需要满足自身需求的高品质医疗保险方案。</Copy>
          </Card.Content>
          <Card.Footer align="center">
            <Copy>
              每周 $17.12 起<sup>2</sup>
            </Copy>
            <PrimaryButton href={cnMigrantJoin}>获取报价</PrimaryButton>
          </Card.Footer>
        </StyledCard>
        <StyledCard icon={CoupleGraphicIcon} align="center">
          <Card.Content title="配偶被保险人">
            <Copy>
              <Bold>配偶医疗保险</Bold>
            </Copy>
            <Copy>
              您与伴侣想要获得能保障一系列服务的保险，因此正在选择最能满足需求的保险方案。
            </Copy>
          </Card.Content>
          <Card.Footer>
            <Copy>
              每周 $37.58 起<sup>3</sup>
            </Copy>
            <PrimaryButton href={cnMigrantJoin}>获取报价</PrimaryButton>
          </Card.Footer>
        </StyledCard>
        <StyledCard icon={FamilyGraphicIcon} align="center">
          <Card.Content title="家庭被保险人">
            <Copy>
              <Bold>家庭医疗保险</Bold>
            </Copy>
            <Copy>
              您刚刚建立了家庭，但肯定还会添宝宝。您需要获得日常与生育相关医疗服务的保障。
            </Copy>
          </Card.Content>
          <Card.Footer>
            <Copy>
              每周 $51.20 起<sup>4</sup>
            </Copy>
            <PrimaryButton href={cnMigrantJoin}>获取报价</PrimaryButton>
          </Card.Footer>
        </StyledCard>
      </Tiles>
    </Stack>
  </Section>
);

const ContactFormSection = (): JSX.Element => (
  <>
    <Section>
      <Box display="flex" justifyContent="left" marginBottom={4} padding={2}>
        <Heading
          color="trueGreen"
          size={{ sm: 4, md: 3, lg: 2 }}
          component="h2"
          style={{ textAlign: 'left' }}
        >
          需要帮助吗？我们可以给您回电
        </Heading>
      </Box>
      <Box display="flex" justifyContent="left" marginBottom={4} padding={2}>
        <Copy measure={false}>
          如果您需要帮助来决定什么方案最适合您，请给我们打电话。在以下的时间内选择一个，通常在一个工作日内（星期一至星期五上午8点至下午5点），我们友好的顾问将会给您回电。或者，立即与顾问交谈，拨打0800
          888 321，选择1。
        </Copy>
      </Box>
      <CallBackModal variant="ExpertCN" isModal={false} />
    </Section>
  </>
);

const DocumentSection = (): JSX.Element => (
  <Section id={'things-to-know'}>
    <Box padding={4} borderRadius="standard">
      <Heading color="trueGreen" size={{ sm: 4, md: 3, lg: 2 }} component="h2">
        <Link href="#things-to-know">Important things to know</Link>
      </Heading>
      <Copy measure={false} component="div">
        <ol style={{ paddingLeft: '1rem' }}>
          <li>
            本情况概览并非保险单据，而是nib保险方案主要特点与赔付内容的总结。保险单据中另含对赔付内容、例外状况、相关等待期的完整阐述。在我司文件页document
            page可以查阅这些保单文件。
          </li>
          <li>
            举例来说,
            一位27岁不吸烟女性、nib标准医院及标准日常医疗保险方案，含每年超额保险额度$500。报价中包含4%直接付款折扣。
          </li>
          <li>
            举例来说,一对配偶，双方均为30岁不吸烟成年人，
            nib标准医院及高级日常医疗保险方案，含每年超额保险额度$500。报价中包含4%直接付款折扣。
          </li>
          <li>
            举例来说,一个家庭，包含两位成年人（35岁不吸烟）及两名子女（21岁以下），nib高级医院及高级日常医疗保险方案，含每年超额保险额度$500。报价中包含4%直接付款折扣。
          </li>
        </ol>
        <p>
          <strong>符合资格</strong>
        </p>
        <p>要申请nib人寿和生活保险，您需完成申请表并符合以下条件：</p>
        <ul>
          <li>
            居住在新西兰，且拥有以下之一：
            <ul>
              <li>新西兰或澳大利亚护照/国籍</li>
              <li>新西兰或澳大利亚永久居民签证（签证上无旅行条件）</li>
              <li>新西兰居民签证（仅适用于人寿保险和/或重大疾病创伤保险）</li>
            </ul>
          </li>
          <li>年龄在18至70岁，可申请人寿保险（人寿保障）</li>
          <li>年龄在18至60岁，可申请生活保险（重大疾病创伤保障或收入保障疾病保障）</li>
          <li>为单一雇主工作每周至少25小时，方可申请生活保险（收入保障疾病保障）</li>
        </ul>

        <p>
          <strong>购买全新人寿和生活保险，即可获得价值$300的Woolworths礼品卡！</strong>
        </p>
        <p>
          <strong>条款与条件</strong>
        </p>
        <ol>
          <li>
            您（即符合资格的保单首次签发时的保单持有人）有资格获得该优惠（“优惠”），如果您：
            <ul>
              <li>
                在2024年11月1日至2024年12月31日之间（或 nib
                可能全权酌情允许的更晚日期）申请全新人寿和生活保险（“符合资格的保单”），并且申请已被nib接受。
              </li>
            </ul>
          </li>
          <li>符合资格的保单生效且支付三个月保费后，第4个月将发送优惠中$300的Woolworths礼品卡。</li>
          <li>每人仅限一张礼品卡，购买多份人寿和生活保险亦同。</li>
          <li>
            礼品卡不可转让、不可退还、不可兑换现金，仅限于Woolworths门店使用，且需遵守Woolworths的标准条款与条件。
          </li>
          <li>
            礼品卡将由PREZZEE Limited电子发放。为此，nib会向PREZZEE
            Limited提供您的姓名和邮箱地址。如需了解您的个人信息的详细处理方式，请参阅PREZZEE的隐私政策。
          </li>
          <li>此优惠不可与其他人寿和生活保险优惠同时使用。</li>
          <li>
            nib可在不会损害已获得优惠的客户权益的前提下修改或更新这些条款。最新更新日期为2024年10月22日，任何更改将发布在nib官网{' '}
            <a href="https://www.nib.co.nz/offer/health-insurance-cn">
              https://www.nib.co.nz/offer/health-insurance-cn
            </a>
            。
          </li>
          <li>nib保留随时取消优惠的权利，恕不另行通知。</li>
          <li>Woolworths并非本次优惠的推广方。</li>
          <li>
            此优惠由nib nz insurance limited推广，0800 555 642，
            <a href="mailto:lifesales@nib.co.nz">lifesales@nib.co.nz</a>。Level 9, 48 Shortland
            Street, Auckland City, 1010
          </li>
        </ol>
      </Copy>
    </Box>
  </Section>
);

const HealthInsuranceCNPage = ({ data }: HealthInsuranceCNProps): JSX.Element => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'WebPage',
    '@id': 'WebPage',
    identifier: 'https://www.nib.co.nz/offer/health-insurance-cn',
    url: 'https://www.nib.co.nz/offer/health-insurance-cn',
    description: 'nib，医疗保险本应如此',
    name: ['您和家人的医疗保险 | nib'],
    isPartOf: 'https://www.nib.co.nz',
  };
  const JSONschema = JSON.stringify(schema);
  const contentfulData = data.allContentfulHealthInsuranceCnPage.edges;
  const campaignInfo = useCampaign(contentfulData.map((d) => d.node));

  return (
    <Layout>
      <Helmet>
        <title>您和家人的医疗保险 | nib</title>
        <meta name="description" content="nib，医疗保险本应如此" />
        <script type="application/ld+json">{JSONschema}</script>
      </Helmet>
      <div>
        {campaignInfo ? (
          <HeaderSection
            title={campaignInfo.heroTitle}
            copy={campaignInfo.heroCopy}
            images={campaignInfo.heroImages}
            legalCopy={campaignInfo.legal}
          />
        ) : (
          <div style={{ minHeight: 500 }}></div>
        )}
      </div>
      <BenefitSection />
      <ValueSection />
      <QuoteSection />
      <ContactFormSection />
      <DocumentSection />
    </Layout>
  );
};

export const HealthInsuranceCNPageQuery = graphql`
  query HealthInsuranceCNPageQuery {
    allContentfulHealthInsuranceCnPage(sort: { fields: startDate, order: DESC }, limit: 2) {
      edges {
        node {
          title
          heroTitle
          heroCopy {
            childMarkdownRemark {
              html
            }
          }
          heroImages {
            desktop {
              file {
                url
              }
            }
            desktop2x4000x1200 {
              file {
                url
              }
            }
            mobile2x800x300 {
              file {
                url
              }
            }
            mobile400x150 {
              file {
                url
              }
            }
            tablet2x1600x580 {
              file {
                url
              }
            }
            tablet800x290 {
              file {
                url
              }
            }
          }
          legal {
            childMarkdownRemark {
              html
            }
          }
          campaignCode
          startDate
        }
      }
    }
  }
`;

export default metrics({ pageName: 'health-insurance-cn' })(HealthInsuranceCNPage);
